import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_router_page = _resolveComponent("router-page")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SiteHeader),
    _createVNode(_component_router_page),
    _createVNode(_component_SiteFooter),
    ($setup.settings && $setup.texts)
      ? (_openBlock(), _createBlock(_component_CookieConsent, {
          key: 0,
          message: $setup.texts?.['consent__message'],
          readMoreUrl: $setup.resolveLink(() => $setup.settings?.privacyPolicyLink)?.url,
          readMoreLabel: $setup.texts?.['consent__read-more-label'],
          agreeLabel: $setup.texts?.['consent__agree-label'],
          disagreeLabel: $setup.texts?.['consent__disagree-label']
        }, null, 8, ["message", "readMoreUrl", "readMoreLabel", "agreeLabel", "disagreeLabel"]))
      : _createCommentVNode("", true)
  ], 64))
}