import {
  inject,
  watch,
  nextTick,
  ref,
} from 'vue';
import {
  routeKey,
  Route,
  meta,
  loadingProgressKey,
} from '@drapejs/core';
import consola from 'consola';

declare global {
  interface Window {
    dataLayer: any
  }
}

/**
 * This will watch for route changes and push a 'page_view' event to GTM dataLayer with the following properties:
 * 
 * - page_location
 * - page_title (based on setMeta value)
 * 
 * Use this in your router-view root component.
 * 
 * @param enricher callback function to add custom properties in the dataLayer page_view event. It can override the default properties if needed.
 */
export function usePageView(enricher?: () => Promise<any>) {
  const route = inject(routeKey, <Route>{});
  const loadingProgress = inject(loadingProgressKey, ref(null));

  watch(loadingProgress, () => {
    if(typeof loadingProgress.value === 'number') {
      return;
    }

    nextTick(async () => {
      if (!hasDataLayer() || !route.pathname) { return; }

      const pageLocation = `${route.pathname}${route.search || ''}`;
      consola.trace('GTM: page_view');
      window.dataLayer.push({
        event: 'page_view',
        page_location: pageLocation,
        page_title: meta.value?.title,
        ...(enricher ? await enricher() : {})
      });
    });
  });
}

/**
 * Provides functions to implement a cookie consent dialog.
 * 
 * The functions will push an event 'set_consent' to dataLayer which can be used
 * in GTM to determine consent status. Possible values are:
 * 
 * - granted
 * - denied
 */
export function useCookieConsent() {

  const consent = ref<boolean | null>(getState());

  setDataLayerConsent(consent.value === true ? true: false);

  return {
    /**
     * Provides an easy was to check if the user has given consent to marketing/tracking.
     * 
     * true if the user has given consent, false is user has rejects, null if the user has not made any decision
     */
    consent,
    /**
     * Call this when the user actively gives consent
     */
    giveConsent() {
      consent.value = true;
      if (hasCookies()) {
        document.cookie = `consent=true|${Date.now()};max-age=31536000`,
        setDataLayerConsent(true);
      }
    },
    /**
     * Call this when the user actively rejects consent
     */
    rejectConsent() {
      consent.value = false;
      if (hasCookies()) {
        document.cookie = "consent=false";
        setDataLayerConsent(false);
      }
    },
  };

  function getState() {
    if (hasCookies()) {
      if (document.cookie.indexOf('consent=true') >= 0) {
        return true;
      }
      
      if (document.cookie.indexOf('consent=false') >= 0) {
        return false
      }
    }

    return null;
  }
}

let lastConsentPushed: boolean | undefined;
function setDataLayerConsent(value: boolean) {
  if (hasDataLayer()) {
    if (value === lastConsentPushed) {
      return;
    }
    consola.trace('GTM: set_consent');
    lastConsentPushed = value;
    window.dataLayer.push({
      event: 'set_consent',
      consent: value ? 'granted' : 'denied'
    });
  }
}

/**
 * Provides functions to push GA4-compatible events to GTM
 */
export function useGA4() {
  let searchDebounce: number | undefined;
  let lastSearchTerm: string | undefined;
  let lastItemView: string | undefined;

  return {
    /**
     * event: search
     * 
     * This function has a built-in debouncing effect, which mean that it can be used on
     * user input if wanted. It also deduplicates if the same searchTerm is submitted
     * multiple times.
     * 
     * @param searchTerm The term that was searched for.
     */
    search(searchTerm: string) {
      if (hasDataLayer()) {
        if (!searchTerm || searchTerm === lastSearchTerm) {
          return;
        }
        lastSearchTerm = searchTerm;
        if (searchDebounce) {
          clearTimeout(searchDebounce);
        }
        searchDebounce = setTimeout(() => {
          consola.trace('GTM: search');
          window.dataLayer.push({
            event: 'search',
            search_term: searchTerm,
          });
        }, 500);
      }
    },
    /**
     * event: add_to_cart
     * 
     * The event includes the following parameters:
     * 
     * - currency
     * - items
     * - value (Automatically calculated based on items' price, discount and quantity)
     */
    addToCart(
      /**
       * Currency of the items associated with the event, in 3-letter ISO 4217 format.
       * 
       * If set, item-level `currency` is ignored.
       * 
       * If not set, currency from the first item in `items` is used.
       */
      currency: string,
      /**
       * The items for the event.
       */
      items: [GA4Item, ...GA4Item[]]
    ) {
      if (hasDataLayer()) {
        consola.trace('GTM: add_to_cart');
        const value = items
          .map((item) => (item.quantity || 0) * ((item.price || 0) - (item.discount || 0)))
          .reduce((res, i) => res + i, 0);
        window.dataLayer.push({
          event: 'add_to_cart',
          currency,
          value,
          items,
        });
      }
    },
    /**
     * event: remove_from_cart
     * 
     * Identical to `addToCart` except event name.
     */
     removeFromCart(currency: string, items: [GA4Item, ...GA4Item[]]) {
      if (hasDataLayer()) {
        consola.trace('GTM: add_to_cart');
        const value = items
          .map((item) => (item.quantity || 0) * ((item.price || 0) - (item.discount || 0)))
          .reduce((res, i) => res + 1, 0);
        window.dataLayer.push({
          event: 'add_to_cart',
          currency,
          value,
          items,
        });
      }
    },
    /**
     * event: begin_checkout
     * 
     * This event signifies that a user has begun a checkout.
     * 
     * The event includes the following parameters:
     * 
     * - currency
     * - value (Automatically calculated based on items' price, discount and quantity)
     * - coupon
     * - items
     */
    beginCheckout(
      /**
       * Currency of the items associated with the event, in
       * [3-letter ISO 4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) format.
       * 
       * If set, item-level `currency` is ignored.
       * @example USD
       */
      currency: string,
      /**
       * The coupon name/code associated with the event.
       * 
       * Event-level and item-level `coupon` parameters are independent.
       */
      coupon?: string,
      /**
       * The items for the event.
       */
      items?: [GA4Item, ...GA4Item[]]
    ) {
      if (hasDataLayer()) {
        consola.trace('GTM: begin_checkout');
        const value = items
          ?.map((item) => (item.quantity || 0) * ((item.price || 0) - (item.discount || 0)))
          .reduce((res, i) => res + 1, 0);
        window.dataLayer.push({
          event: 'begin_checkout',
          currency,
          value,
          coupon,
          items,
        });
      }
    },
    /**
     * event: purchase
     * 
     * This event signifies when one or more items is purchased by a user.
     * 
     * Parameters include:
     * 
     * - currency
     * - transaction_id
     * - value (Automatically calculated based on items' price, discount, quantity)
     * - affiliation
     * - coupon
     * - shipping
     * - tax
     * - items
     */
    purchase(
      /**
       * Currency of the items associated with the event, in
       * [3-letter ISO 4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) format.
       * 
       * If set, item-level `currency` is ignored.
       * @example USD
       */
      currency: string,
      /**
       * The unique identifier of a transaction.
       * 
       * The `transaction_id` parameter helps you avoid getting duplicate events for a purchase.
       * @example T_12345
       */
      transactionId: string,
      /**
       * The items for the event.
       */
      items?: [GA4Item, ...GA4Item[]],
      /**
       * Tax cost associated with a transaction.
       * @example 1.11
       */
      tax?: number,
      /**
       * Shipping cost associated with a transaction.
       * @example 3.33
       */
      shipping?: number,
      /**
       * A product affiliation to designate a supplying company or brick and mortar store location.
       * 
       * Event-level and item-level `affiliation` parameters are independent.
       * @example Google Store
       */
      affiliation?: string,
      /**
       * The coupon name/code associated with the event.
       * 
       * Event-level and item-level `coupon` parameters are independent.
       * @example SUMMER_FUN
       */
      coupon?: string
    ) {
      if (hasDataLayer()) {
        consola.trace('GTM: purchase');
        const value = items
          ?.map((item) => (item.quantity || 0) * ((item.price || 0) - (item.discount || 0)))
          .reduce((res, i) => res + 1, 0);
        window.dataLayer.push({
          event: 'purchase',
          transaction_id: transactionId,
          currency,
          tax,
          shipping,
          affiliation,
          value,
          coupon,
          items,
        });
      }
    },
    /**
     * event: view_item
     * 
     * This event signifies that some content was shown to the user. Use this event to discover the most popular items viewed.
     * 
     * Parameters include:
     * 
     * - currency
     * - value (Automatically calculated based on item's price and discount)
     * - items
     */
    viewItem(
      /**
       * Currency of the items associated with the event, in
       * [3-letter ISO 4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) format.
       * 
       * If set, item-level `currency` is ignored.
       * 
       * The function has built-in deduplication based on item_id
       * 
       * @example USD
       */
      currency: string,
      /**
       * The items for the event.
       */
      item: GA4Item
    ) {
      if (hasDataLayer()) {
        if (item.item_id && item.item_id === lastItemView) {
          return;
        }
        lastItemView = item.item_id;
        consola.trace('GTM: view_item');
        const value = ((item.price || 0) - (item.discount || 0));
        window.dataLayer.push({
          event: 'view_item',
          currency,
          value,
          items: [item],
        });
      }
    }
  };
}

function hasCookies() {
  return typeof document !== 'undefined';
}
function hasDataLayer() {
  return typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined';
}

/**
 * For full reference: https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 */
export interface GA4Item {
  /**
   * The ID of the item.
   * @required if `item_name` is not specified
   * @example SKU_12345
   */
  item_id?: string
  /**
   * The name of the item.
   * @required if `item_id` is not specified
   * @example Stan and Friends Tee
   */
  item_name?: string
  /**
   * A product affiliation to designate a supplying company or brick and mortar store location.
   * 
   * Event-level and item-level `affiliation` parameters are independent.
   * @example Google Store
   */
  affiliation?: string
  /**
   * The coupon name/code associated with the item.
   * 
   * Event-level and item-level `coupon` parameters are independent.
   * @example SUMMER_FUN
   */
  coupon?: string
  /**
   * The currency, in [3-letter ISO 4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) format.
   * 
   * If set, event-level currency is ignored.
   * 
   * Multiple currencies per event is not supported. Each item should set the same currency.
   * @example USD
   */
  currency?: string
  /**
   * The monetary discount value associated with the item.
   * @example 2.22
   */
  discount?: number
  /**
   * The index/position of the item in a list.
   * @example 5
   */
  index?: number
  /**
   * The brand of the item.
   * @example Google
   */
  item_brand?: string
  /**
   * The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category.
   * @example Apparel
   */
  item_category?: string
  /**
   * The second category hierarchy or additional taxonomy for the item.
   * @example Adult
   */
  item_category2?: string
  /**
   * The third category hierarchy or additional taxonomy for the item.
   * @example Shirts
   */
  item_category3?: string
  /**
   * The fourth category hierarchy or additional taxonomy for the item.
   * @example Crew
   */
  item_category4?: string
  /**
   * The fifth category hierarchy or additional taxonomy for the item.
   * @example Short sleeve
   */
  item_category5?: string
  /**
   * The ID of the list in which the item was presented to the user.
   * 
   * If set, event-level `item_list_id` is ignored.
   * If not set, event-level `item_list_id` is used, if present.
   * @example related_products
   */
  item_list_id?: string
  /**
   * The name of the list in which the item was presented to the user.
   * 
   * If set, event-level `item_list_name` is ignored.
   * If not set, event-level `item_list_name` is used, if present.
   * @example Related products
   */
  item_list_name?: string
  /**
   * The item variant or unique code or description for additional item details/options.
   * @example green
   */
  item_variant?: string
  /**
   * The location associated with the item. It's recommended to use the
   * [Google Place](https://developers.google.com/maps/documentation/places/web-service/place-id)
   * ID that corresponds to the associated item. A custom location ID can also be used.
   * 
   * If set, event-level `location_id` is ignored.
   * If not set, event-level `location_id` is used, if present.
   */
  location_id?: string
  /**
   * The monetary price of the item, in units of the specified currency parameter.
   * @example 9.99
   */
  price?: number
  /**
   * Item quantity.
   * @example 1
   */
  quantity?: number
}