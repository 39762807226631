import Vue, { createApp, createSSRApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import drapejs, { Options } from '@drapejs/core';
import Storyblok, { StoryblokOptions } from '@distancify/drapejs-storyblok';
import StandardComponents from '@distancify/storyblok-standard-components';

import consola from 'consola';

import './style/index.css'

consola.level = 5;

export default function(workerFactory?: () => Worker) {
  let create;
  if (process.env.SSR) {
    create = createSSRApp;
  } else {
    create = createApp;
  }

  const app = create(App)
    .use(drapejs, <Options>{
      pageComponents: {
        Settings: defineAsyncComponent(() => import('./pages/Settings.vue')),
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        NotFound: defineAsyncComponent(() => import('./pages/NotFound.vue')),
        BlogIndex: defineAsyncComponent(() => import('./pages/BlogIndex.vue')),
        BlogPost: defineAsyncComponent(() => import('./pages/BlogPost.vue')),
      },
      workerFactory,
    })
    .use(Storyblok, <StoryblokOptions>{
      token: "7C5TE0WhBj3krrCKhLrzQwtt",
      fetchDraft: process.env.NODE_ENV == 'development',
      blockComponents: {
        ...StandardComponents.blocks,
        Text: defineAsyncComponent(() => import('./blocks/Text.vue')),
        Margin: defineAsyncComponent(() => import('./blocks/Margin.vue')),
        VideoPlayer: defineAsyncComponent(() => import('./blocks/VideoPlayer.vue')),
        Button: defineAsyncComponent(() => import('./blocks/Button.vue')),
        BubbleButton: defineAsyncComponent(() => import('./blocks/BubbleButton.vue')),
      }
    });

  app.config.warnHandler = function(msg, vm, trace) {
    console.warn(msg, vm, trace);
  }
  
  return app;
}
